<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Uji Petik Pemda Provinsi</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Nama Provinsi</th>
                                          <th>Total Nilai Pemaparan</th>
                                          <th>Total Nilai Uji Petik</th>
                                          <th>Total Nilai Akhir</th>
                                          <!-- <th>Peringkat</th> -->
                                          <th>Catatan</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td><div style="width:150px">{{row.daerah}}</div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_pemaparan)}}&nbsp; &nbsp;</div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_uji_petik)}}&nbsp; &nbsp;</div></td>
                                          <td><div>{{format_desimal(row.total_akhir)}}&nbsp; &nbsp;</div></td>
                                          <!-- <td>
                                              <div style="text-align:center;" v-if="row.peringkat_akhir == 1000">
                                                  Keluar
                                              </div>
                                              <div v-else style="text-align:center;">{{row.peringkat_akhir}}</div>
                                          </td> -->
                                          <td><button data-toggle="modal" data-target=".catatan" @click="getcatatan(row.idptsp)" style="margin:5px" class="btn btn-warning">Lihat</button></td>
                                          <td>
                                          <div style="width:350px">
                                            <b-button variant="primary" style="margin:5px" @click="calculate(row.idptsp)">Calculate</b-button>
                                            <button data-toggle="modal" data-target=".bd-example-modal-lg" @click="lihat(row.idptsp)" style="margin:5px" class="btn btn-success">Lihat</button>
                                            <button  @click="badaerah(row.file_ba_uji_petik)" style="margin:5px" class="btn btn-warning">BA</button>
                                          </div></td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!-- <button @click="getrekomendasi" data-toggle="modal" style="margin-right:10px;" data-target="#rekomendasi" class="btn btn-primary"><i class="flaticon-file-1"></i> Rekomendasi</button> -->
                    <button @click="hitung" style="margin-right:10px;" type="button" class="btn btn-primary"><i class="flaticon-star"></i> Pemeringkatan</button>
                    <button data-toggle="modal" data-target="#daerah" class="btn btn-success"><i class="fa fa-print"></i> Cetak Berita Acara</button>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Modal lihat -->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Penilaian</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Provinsi</th>
                                            <th>Penilai</th>
                                            <th>Nilai Uji Petik</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in penilaian" :key="row.id">
                                            <td></td>
                                            <td>{{row.peserta}}</td>
                                            <td>
                                                {{row.namapenilai}}
                                            </td>
                                            <td>
                                                {{format_desimal(row.total_skor)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- lihat catatan -->
            <div class="modal fade catatan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example3" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-catatan" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- detail catatan -->
            <div class="modal fade" id="modal-catatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                   

                                    <div class="form-group row">
                                        <label class="col-4">Tipe</label>
                                        <div class="col-8">
                                            <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input type="text" v-model="getCatatan.judul" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Lembaga</label>
                                        <div class="col-lg-8">
                                            <input type="text" v-model="getCatatan.lembaga" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <div v-html="getCatatan.deskripsi"></div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input type="text" v-model="getCatatan.sumber" class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file>
                                            <div v-if="getCatatan.file !== null">
                                                <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            
                        </div>
                        </div>
                    </div>
                </div>

            <!-- modal masukkan daerah-->
            <div class="modal fade" id="daerah" tabindex="-1" role="dialog" aria-labelledby="persetujuan" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="persetujuan">Menyetujui Uji Petik</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="alasan" class="form-group row">
                        <label class="col-lg-4 col-form-label">Daerah</label>
                            <div class="col-lg-8">
                                <input type="text" v-model="daerah" class="form-control"/>
                            </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary" @click="cetakba" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal">Simpan</button>
                </div>
                </div>
            </div>
            </div>

            <!-- modal rekomendasi -->
            <div class="modal fade " tabindex="-1" id="rekomendasi" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Rekomendasi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                <table id="example4" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Provinsi</th>
                                            <th>File</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in datarekomendasi" :key="row.id">
                                            <td></td>
                                            <td>{{row.daerah}}</td>
                                            <td>
                                                <div v-if="row.file_rekomendasi != null">
                                                    <a :href="row.file_rekomendasi" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                </div>
                                            </td>
                                            <td>
                                                <button @click="getrekomendasidaerah(row.id)" data-toggle="modal" data-target="#edit-rekomendasi" class="btn btn-primary">Unggah</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- edit rekomendasi -->
            <div class="modal fade" id="edit-rekomendasi" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Upload Rekomendasi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form">
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Unggah File</label>
                                        <div class="col-lg-8">
                                            <b-form-file v-model="getdatarekomendasi.file_rekomendasi" type="file" ref="file"></b-form-file>
                                        </div>
                                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="uploadrekomendasi(getdatarekomendasi.id, getdatarekomendasi.file_rekomendasi)" data-dismiss="modal"><i class="fas fa-save"></i> Simpan</button>
                            
                        </div>
                        </div>
                    </div>
                </div>

        </div>
    <!-- </div>
  </div> -->


  
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
      return {
        url:localStorage.getItem('baseapi'),
        test:'hai',
        tahun: new Date().getFullYear(),
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('id_token'),
        totskor:[],
        idruangan:'',
        daerah:'',
        ruanganpenilai:[],
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        timpenilai:[],
        p1a:'',
        p1b:'',
        p1c:'',
        p2a:'',
        p2b:'',
        p3a:'',
        p3b:'',
        dataprovinsi:[],
        show:false,
        penilaian:[],
        catatann:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        getdatarekomendasi:[],
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        datarekomendasi:[],
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Uji Petik Pemda Provinsi" }]);
    this.getpenilai()
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    setPeringkatBaru(peringkatbaru){
        Swal.fire({
        title: 'Apakah anda yakin?',
        text: "Anda tidak akan dapat mengembalikan ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya'
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const id = peringkatbaru.id;
            const peringkatakhir = 1000;
            console.log(peringkatakhir);
            axios.put(this.url+"/total_uji_petik_provinsi/"+id,
            {
                peringkat_petik:peringkatakhir
            }).then((res) => {
                        // this.$bvToast.toast('Berhasil Diedit', {
                        //     title: `Success `,
                        //     variant: `success`,
                        //     solid: true
                        // })
                        Swal.fire('Berhasil diubah', '', 'success')
                        console.log(res);
                        this.getdata()
                        // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
                    return res;
                    })
                    .catch((err) => {
                        console.log(err)
                        Swal.fire(err.response.data.message, '', 'error')
                        // this.$bvToast.toast(err.response.data.message, {
                        // title: `Failed `,
                        // variant: `danger`,
                        // solid: true
                        // })
                        return err;
                });
        }
        })
  
    },
    calculate(id) {
        axios.put(this.url+`/gabung_skor_uji_petik`,
            {
                idpeserta:id,
                tahun:this.tahun
            }
            ,{
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Dicalculate', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast(err.response.data.message, {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    getcatatan(idptsp){
        $('#example3').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_provinsi?filter=idptsp,=,"+idptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                var catatannomine = response.data.data;
                this.catatann = catatannomine;
                this.initDatatable3()
                // this.idpenilaian=response.data.data.id;
                console.log(response.data.data)
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
        });
    },
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_provinsi?filter=id,=,"+id).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    getpenilai(){
        axios.get(this.url+"/tim_penilai?filter=username,like,timpenilai%", {
                headers: {
                    'xth': this.token
                }
            })
      .then(response => {
          var data = response.data.data;
          for (let index = 0; index < data.length; index++) {
                // const element = array[index];
                this.timpenilai.push({
                    id: parseInt(data[index].id),
                    nama:data[index].name,
                })
                // console.log(this.wilayah)
            }
            this.gettimteknis()
      }).catch(error=>{
          // localStorage.setItem('idpenilaian', 'undefined')
      console.log(error)
      return error
      // this.loaddata()
      });
    },
    gettimteknis(){
        axios.get(this.url+"/users?filter=username,like,timteknispenilai%", {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            var result = response.data.data;
            for(let i=0;i<result.length;i++){
                let temp = result[i]['alamat'].split("-");
                if(temp.length <= 1){
                    continue;
                }
                this.timpenilai.push({
                    id: parseInt(result[i].id),
                    nama:result[i].name,
                })
            }
        }).catch(error=>{
            console.log(error)
            return error
        });
    },
    loaddata(){
      this.totskor = null;
      axios.get(this.url+"/tabel_nomine_provinsi?filter=tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
      .then(response => {
          var nominee = response.data.data;
          this.totskor = nominee;
          this.initDatatable();
          this.initDatatable4();
      }).catch(error=>{
          // localStorage.setItem('idpenilaian', 'undefined')
          console.log(error)
          return error
          // this.loaddata()
          });
    },
    getrekomendasi(){
        $('#example4').DataTable().destroy();
            this.datarekomendasi = this.totskor
            this.initDatatable4()    
    },
    getrekomendasidaerah(id){
        axios.get(this.url+"/tabel_nomine_provinsi?filter=id,=,"+id, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            this.getdatarekomendasi.id = id;
                this.getdatarekomendasi.file_rekomendasi = response.data.data[0].file_rekomendasi;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
              },
              columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
              }],
              buttons: [
                // 'excel', 'pdf',
                {
                  extend: 'excelHtml5',
                    title: 'UJI PETIK PEMDA PROVINSI KEMENTERIAN INVESTASI/BKPM',
                    text : 'Excel',
                    exportOptions: {
                        columns: [ 1, 2, 3, 4],
                        orthogonal: 'export',
                        format: {
                            header: function ( data, columnIdx ) {
                            
                                  return data;
                            
                            },
                            body: function(data, row, column, node) {
                                data = $('<p>' + data + '</p>').text();
                                return data.replace(',', '.');
                            },
                        }
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Uji Petik Pemda Provinsi ' + date;
                    },
                },
                {
                    extend: 'pdf',
                    text : 'PDF',
                    orientation: 'potrait',
                    title: 'UJI PETIK PEMDA PROVINSI KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [1, 2, 3, 4]
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Uji Petik Pemda Provinsi ' + date;
                    },
                }
            ],
            order: [[4, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    initDatatable4() {
        setTimeout(() => {
        var t =  $('#example4').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    initDatatable3() {
        setTimeout(() => {
        var t =  $('#example3').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    lihat(idptsp){
        $('#example2').DataTable().destroy();
        this.penilaian = null,
        // this.$loading(true);
        axios.get(this.url+"/tabel_nilai_peserta_uji_petik?filter=idpeserta,=,"+idptsp+';tahun,=,'+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                // this.$loading(false);
                var penilaianomine = response.data.data;
                // console.log(penilaianomine)
                var j = 0;
                var k =0;
                var l = 0;
                for (j in penilaianomine) {
                    for (k in this.timpenilai) {
                        if (this.timpenilai[k].id == penilaianomine[j].idpenilai) {
                            penilaianomine[j]['namapenilai'] = this.timpenilai[k].nama
                            console.log(this.timpenilai[k].nama)
                        }
                    }
                    for (l in this.totskor) {
                        if (this.totskor[l].idptsp == penilaianomine[j].idpeserta) {
                            penilaianomine[j]['peserta'] = this.totskor[l].daerah
                            console.log(this.totskor[l].nama)
                        }
                    }
                }
                
                this.penilaian = penilaianomine;
                console.log(this.penilaian)
                this.initDatatable2()
            }).catch(error=>{
                // this.$loading(false);
                return error
        });
    },
    hitung(){
        axios
            .post(
                this.url+'/total_uji_petik_provinsi_rerank',
            {
                
            },
            {
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
              return res;
            })
            .catch((err) => {
              console.log(err)
              this.$bvToast.toast('Gagal', {
              title: `Failed `,
              variant: `danger`,
              solid: true
            })
            return err;
        });
    },
    cetakba(){
        const tahun = this.tahun;
        const daerah = this.daerah;
        axios
            .post(this.url+"/total_uji_petik_provinsi_ba_pleno",
            {
                tahun,
                daerah,
               
            },
            {
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Cetak BA', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                // this.loaddata()
                console.log(res.data.data);
                window.open(res.data.data.file_ba, '_blank');
                
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Cetak BA', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    badaerah(linkba){
        if (linkba == null) {
            Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
            this.loaddata()
        }else{
            window.open(linkba, '_blank')
        }
    },
    uploadrekomendasi(id, data){
        let formData = new FormData();
                formData.append('file_rekomendasi', data);
          axios
            .post(this.url+`/tabel_nomine_provinsi/`+id, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil diupload', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
                this.getrekomendasi()
              return response
              
            })
            .catch(error=>{
                this.$bvToast.toast(error.response.data.message, {
                title: `Failed `,
                variant: `danger`,
                solid: true
                })
              return error
            });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('edit-rekomendasi')
        })
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
